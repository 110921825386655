<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'ce-digital-financial-clients-active' }"
        exact
        :link-classes="['px-3', bgTabsNavs]"
        exact-active-class="active"
      >Active <span
        v-if="G_CLIENTS_COUNTER_TOTAL_ACTIVE > 0"
        class="ml-2"
      ><feather-icon
        icon
        :badge="G_CLIENTS_COUNTER_TOTAL_ACTIVE"
        badge-classes="badge-danger"
      /></span></b-nav-item>
      <b-nav-item
        :to="{ name: 'ce-digital-financial-clients-loyal' }"
        exact
        :link-classes="['px-3', bgTabsNavs]"
        exact-active-class="active"
      >Loyal <span
        v-if="G_CLIENTS_COUNTER_TOTAL_LOYAL > 0"
        class="ml-2"
      ><feather-icon
        icon
        :badge="G_CLIENTS_COUNTER_TOTAL_LOYAL"
        badge-classes="badge-danger"
      /></span></b-nav-item>
      <b-nav-item
        :to="{ name: 'ce-digital-financial-clients-hold' }"
        exact
        :link-classes="['px-3', bgTabsNavs]"
        exact-active-class="active"
      >Outstanding <span
        v-if="G_CLIENTS_COUNTER_TOTAL_OUTSTANDING > 0"
        class="ml-2"
      ><feather-icon
        icon
        :badge="G_CLIENTS_COUNTER_TOTAL_OUTSTANDING"
        badge-classes="badge-danger"
      /></span></b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import ModalSearchPayment from '@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ModalSearchPayment,
  },
  data() {
    return {
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    ...mapGetters({
      G_CLIENTS_COUNTER: 'FinancialStore/G_CLIENTS_COUNTER',
      G_CLIENTS_COUNTER_TOTAL_ACTIVE: 'FinancialStore/G_CLIENTS_COUNTER_TOTAL_ACTIVE',
      G_CLIENTS_COUNTER_TOTAL_LOYAL: 'FinancialStore/G_CLIENTS_COUNTER_TOTAL_LOYAL',
      G_CLIENTS_COUNTER_TOTAL_OUTSTANDING: 'FinancialStore/G_CLIENTS_COUNTER_TOTAL_OUTSTANDING',
    }),
  },
  async created() {
    await Promise.all([this.moduleId(), this.A_SET_CLIENTS_COUNTER()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    ...mapActions({
      A_SET_CLIENTS_COUNTER:
        'FinancialStore/A_SET_CLIENTS_COUNTER',
    }),
  },
};
</script>
